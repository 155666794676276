<template>
  <v-form
    class="tectonic-form-container container d-flex flex-column"
    v-model="isValid"
    ref="sampleFrom"
    lazy-validation
    @submit.prevent="addSampleImage"
  >

    <v-file-input
      accept="image/png, image/jpeg, image/bmp"
      prepend-icon="mdi-camera"
      label="Выберите изображение образца"
      v-model="image"
    ></v-file-input>
    <v-btn
      @click="addSampleImage"
      :disabled="isSending"
    >Добавить
    </v-btn>
  </v-form>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "SampleImageForm",
  props: ['sampleId'],
  data() {
    return {
      isValid: true,

      description: '',
      sampleName: '',
      image: null,

      isSending: false
    }
  },
  methods: {
    ...mapMutations(['UPDATE_SAMPLE']),
    async addSampleImage() {
      await this.$refs.sampleFrom.validate()

      if (!this.isValid) {
        return
      }

      let formData = new FormData()
      formData.append('image.image', this.image)

      this.isSending = true

      await this.$api.patch(`sample/${this.sampleId}/`,
        formData
      ).then(async ({data}) => {
        this.isSending = false
        console.log(data)
        this.UPDATE_SAMPLE(data)
        await this.$refs.sampleFrom.reset()
        this.value = false
        this.$emit('add', data)
      }).catch(err => {
        console.error(err)
        this.isSending = false
      })
    },
  }
}
</script>

<style scoped>

</style>